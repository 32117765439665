














































import Vue from 'vue';
import MembershipResourceViewModel from '@/src/services/viewModel/resource/MembershipResourceViewModel';

export default Vue.extend({
  auth: true,
  hasBackButton: true,
  data: () => ({
    definition: undefined as any,
    contactId: undefined as any,
    memberData: new MembershipResourceViewModel() as MembershipResourceViewModel | undefined,
  }),
  watch: {
    memberData: {
      immediate: true,
      handler() {
        this.contactId = this.memberData?.memberContact?.contactId ?? '-';
      },
    },
  },
  async mounted() {
    this.memberData = await this.$service.api.memberships.getMyMembership();
    this.definition = await this.$service.api.dynamicForms.getMemberChangeAddress();
  },
  methods: {
    onSubmit() {
    },
    getSubscription() {
      return this.$service.api.subscriptions.getMyGastroJournalDetail();
    },
  },
});
